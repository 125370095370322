import { ListItemButton , ListItemIcon, ListItemText } from '@mui/material'
import * as Icons from '@mui/icons-material'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from 'reducers'
import { SettingsState, setThemeType } from 'reducers/settings'

const ThemeToggler = (): React.ReactElement => {
  const dispatch = useDispatch()
  const settings = useSelector<AppState, SettingsState>(state => state.settingsState)

  const isLight = settings.themeType === 'light'
  const onClick = (): void => {
    dispatch(setThemeType(isLight ? 'dark' : 'light'))
  }

  return (
    <ListItemButton  key='theme-toggler' onClick={onClick}>
      <ListItemIcon>
        {
          isLight
            ? <Icons.Brightness4 />
            : <Icons.Brightness7 />
        }
      </ListItemIcon>
      <ListItemText primary='Theme' />
    </ListItemButton >
  )
}

export default ThemeToggler
