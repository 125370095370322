import { createTheme, Theme } from '@mui/material'

export const getMuiTheme = (mode: 'light' | 'dark'): Theme => {
  const theme = createTheme({
    typography: {
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Verdana"',
        'Helvetica',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif'
      ].join(',')
    },
    palette: {
      mode
    }
  })

  return createTheme(theme, {
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          '*::-webkit-scrollbar': {
            width: '10px'
          },
          '*::-webkit-scrollbar-track': {
            background: theme.palette.mode === 'light' ? theme.palette.primary.main : theme.palette.background.paper
          },
          '*::-webkit-scrollbar-thumb': {
            'border-radius': '10px',
            background: theme.palette.secondary.light
          },
          '*::-webkit-scrollbar-thumb:hover': {
            background: theme.palette.secondary.dark
          }
        }
      },
      MuiDrawer: {
        styleOverrides: {
          paper: {
            width: '240px'
          }
        }
      }
    }
  })
}

export enum ResponsiveType {
  Desktop = 'lg',
  Tablet = 'sm',
  Mobile = 'xs',
}

export const getResponsiveQuery = (theme: Theme, type: ResponsiveType, direction: 'up' | 'down'): string => {
  const size = type.valueOf() as ('xs' | 'sm' | 'md' | 'lg' | 'xl')
  return theme.breakpoints[direction](size)
}
