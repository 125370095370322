import { Session } from 'reducers/session'

export const test = async (): Promise<Response> => {
  return await fetch(`${process.env.REACT_APP_API_ENDPOINT ?? ''}/test`)
}

export const getSession = async (): Promise<Session> => {
  return await fetch(`${process.env.REACT_APP_API_ENDPOINT ?? ''}/session`, {
    method: 'GET',
    mode: 'no-cors',
    credentials: 'include'
  })
    .then(async response => {
      if (!response.ok) { throw Error(response.statusText) }
      return await response.json()
    })
}

export const postSession = async (jwt?: string): Promise<Session> => {
  return await fetch(`${process.env.REACT_APP_API_ENDPOINT ?? ''}/session`, {
    method: 'POST',
    mode: 'cors',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ jwt })
  })
    .then(async response => {
      if (!response.ok) { throw Error(response.statusText) }
      return await response.json()
    })
}

export const deleteSession = async (): Promise<void> => {
  return await fetch(`${process.env.REACT_APP_API_ENDPOINT ?? ''}/session`, {
    method: 'DELETE',
    mode: 'cors',
    credentials: 'include'
  })
    .then(response => {
      if (!response.ok) { throw Error(response.statusText) }
    })
}
